const models = `year/month/date/dates/week/datetime/datetimerange/daterange/monthrange`.split(
  '/'
)
const placeholders = {
  year: '选择到年',
  month: '选择到月',
  date: '选择到天'
}
function getEdit(variable, model) {
  return {
    type: 'datetime',
    variable,
    mode: 'edit',
    model,
    label: 'model:' + model,
    placeholder: placeholders[model] || '选择',
    required: true
  }
}
function getView(variable, model) {
  return {
    mode: 'view',
    type: 'datetime',
    variable,
    label: `model:${model} 查看`
  }
}
function getItemsInModels() {
  const items = []
  for (const index in models) {
    items.push(getEdit(models[index], models[index]))
    items.push(getView(models[index], models[index]))
  }
  return items
}
export default function() {
  const items = getItemsInModels()
  items.push({
    type: 'form-submit',
    label: '提交',
    src: '/home/login',
    confirm: '一旦提交将不能修改'
  })
  return {
    doc_title: 'datetime 时间选择',
    items,
    variables: {
      year: '2021',
      month: '2021-04-01',
      date: '2021-04-01'
    }
  }
}
