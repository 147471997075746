const options = [
  {
    value: '0',
    label: '总裁办'
  },
  {
    value: '1',
    label: '综合中心'
  },
  {
    value: '2',
    label: '研发中心'
  },
  {
    value: '3',
    label: '业务中心'
  },
  {
    value: '4',
    label: '品牌中心'
  },
  {
    value: '5',
    label: '制造中心'
  },
  {
    value: '6',
    label: '新疆极飞'
  },
  {
    value: '7',
    label: '河南极飞'
  }
]
const options_text = [
  '总裁办',
  '综合中心',
  '研发中心',
  '业务中心',
  '品牌中心',
  '制造中心',
  '新疆极飞',
  '河南极飞'
]
function getShowRule(fn, control_id, value) {
  return {
    fn,
    items: [
      {
        control_id,
        value
      }
    ]
  }
}
const showByCheckGround = getShowRule('type', 'type', 'checkgroup')
const showByRadio = getShowRule('type', 'type', 'radio')
const checkGroup_items = [
  {
    type: 'text',
    label: 'checkgroup',
    mode: 'view',
    variable: 'checkgroup_info'
  },
  {
    type: 'checkgroup',
    variable: 'checkgroup_1',
    label: '1：options:[{value,label}]',
    required: true,
    mode: 'edit',
    options: options,
    tips: 'variable:checkgroup_1'
  },
  {
    type: 'text',
    label: '结果',
    mode: 'view',
    variable: 'checkgroup_1',
    tips: 'variable:checkgroup_1'
  },
  {
    type: 'checkgroup',
    variable: 'checkgroup_2',
    label: '2：options:[x1,x2,x3]',
    required: true,
    mode: 'edit',
    options: options_text,
    tips: 'variable:checkgroup_2'
  },
  {
    type: 'text',
    label: '结果',
    mode: 'view',
    variable: 'checkgroup_2',
    tips: 'variable:checkgroup_2'
  }
]
const radio_items = [
  {
    type: 'text',
    label: 'radio',
    mode: 'view',
    variable: 'radio_info'
  },
  {
    type: 'radio',
    variable: 'radio_1',
    label: '1：options:[{value,label}]',
    required: true,
    mode: 'edit',
    options: options,
    tips: 'variable:radio_1'
  },
  {
    type: 'text',
    label: '结果',
    mode: 'view',
    variable: 'radio_1',
    tips: 'variable:radio_1'
  },
  {
    type: 'radio',
    variable: 'radio_2',
    label: '2：options:[x1,x2,x3]',
    required: true,
    mode: 'edit',
    options: options_text,
    tips: 'variable:radio_2'
  },
  {
    type: 'text',
    label: '结果',
    mode: 'view',
    variable: 'radio_2',
    tips: 'variable:radio_2'
  }
]
function invokeShowRule(items, showRule) {
  return items.map(item => {
    return {
      ...item,
      showRule
    }
  })
}
export default function() {
  return {
    doc_title: 'checkgroup：多选,radio：单选',
    items: [
      {
        type: 'radio',
        variable: 'type',
        label: '功能目的：从options中选择',
        required: true,
        mode: 'edit',
        options: [
          {
            value: 'checkgroup',
            label: 'checkgroup：多选'
          },
          {
            value: 'radio',
            label: 'radio：单选'
          }
        ]
      },
      ...invokeShowRule(checkGroup_items, showByCheckGround),
      ...invokeShowRule(radio_items, showByRadio)
    ],
    variables: {
      checkgroup_info: '多选，选择结果是个数组值',
      radio_info: '单选，选择结果是选中的值'
    }
  }
}
