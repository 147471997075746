const jsonDataBase = require.context('./diyForm/', true, /.json$/i)
const jsDataBase = require.context('./diyForm/', true, /.js$/i)
function getModuleByIndex(index, context) {
  return context(context.keys()[index])
}
function searchPathIndex(arr, path) {
  return arr.findIndex(key => key.includes(path))
}
export function req(path) {
  let index = searchPathIndex(jsonDataBase.keys(), path)
  if (index >= 0) return getModuleByIndex(index, jsonDataBase)
  index = searchPathIndex(jsDataBase.keys(), path)
  if (index >= 0) {
    const mModule = getModuleByIndex(index, jsDataBase)
    return mModule.default()
  }
}
let mkeys = jsonDataBase.keys()
mkeys.push(...jsDataBase.keys())
export const keys = mkeys
export default req
