function getTabs(mode, variable, placeholder, max) {
  const cfg = {
    type: 'tabs',
    variable,
    mode,
    placeholder,
    label: `tabs:${mode}`
  }
  if (max !== undefined) cfg.max = max
  return cfg
}
function getEditTabs() {
  return getTabs.bind(null, 'edit')
}
function getViewTabs() {
  return getTabs.bind(null, 'view')
}
export default function() {
  return {
    doc_title: 'tabs 编辑标签',
    items: [
      getEditTabs()('edit', '来点啥'),
      getEditTabs()('edit', '来点啥', 4),
      getViewTabs()('edit')
    ],
    variables: {
      edit: []
    }
  }
}
